<template>
	<grid-template cols="4" class="download-section">
		<template #title>
			<cms-text :props="{variant:'medium-heading'}" :value="`${prefix}.title`" v-animate="`slideRight`"/>
		</template>
		<download-card v-for="file in value.files" :key="file.name" :item="file" v-animate="`opacity`"/>
	</grid-template>
</template>
<script>
import DownloadCard from '~/website/front/components/molecules/cards/DownloadCard/DownloadCard.vue'
import GridTemplate from '~/website/front/templates/GridTemplate.vue'

export default {
	components: {
		DownloadCard,
		GridTemplate
	},
	props: {
		prefix: String,
		value: Object
	},
	async prefetch () {
		await this.$app.translator.prefetch('download')
	}

}
</script>
<style lang="scss" scoped>
	.download-section {
        background: $white;
		padding: 4rem 1.5rem;
        @include media-breakpoint-up(sm) {
            background: initial;
        }
		@include media-breakpoint-up(xl) {
			padding: 8rem 1.5rem;
		}
	}
</style>
